import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; //

export const useDefaultTheme = () => {
  const { pageTheme } = useStaticQuery(graphql`
    query {
      pageTheme: sanityTheme(title: { eq: "Dejima Theme" }) {
        ...Theme
      }
    }
  `);
  return pageTheme || {};
};
