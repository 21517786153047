import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import { useEmblaCarousel } from 'embla-carousel/react';

import { useAppContext } from '../../state';

import { ResponsiveImage } from '../media/responsiveImage';

const CaseCard = ({ portalImage, title, projectTags, category, slug }) => {
  const { setCursorState } = useAppContext();
  return (
    <Link
      to={`/case-studies/${slug && slug.current}`}
      className="w-screen-1/2 pr-10 grid grid-rows-1 grid-cols-1"
      style={{ flex: '0 0 auto' }}
      onMouseEnter={() => setCursorState('case')}
      onMouseLeave={() => setCursorState('default')}
      onClick={() => setCursorState('default')}
    >
      <div className="row-start-1 col-start-1 relative z-20 p-6">
        <span className="bg-dejima-red rounded-full px-10 py-1 text-white text-xs font-medium">
          {category}
        </span>
      </div>
      <div className="aspect-w-8 aspect-h-5 col-start-1 row-start-1 relative">
        {portalImage && (
          <ResponsiveImage {...portalImage} className="absoulute top-0 left-0 object-cover" />
        )}
      </div>
      <div className="row-start-1 col-start-1 self-end z-20 relative text-white p-6">
        <h2 className="text-4xl xl:text-5xl font-black">{title}</h2>
        <h4 className="text-2xl">
          {projectTags.map((item) => (
            <>{item}, </>
          ))}
        </h4>
      </div>
    </Link>
  );
};

export const CaseStudiesDesktop = ({ items, caseStudiesHeading }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [emblaRef, embla] = useEmblaCarousel({
    loop: false,
    dragFree: false,
    align: 'start',
    containScroll: true,
  });

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <div className="h-screen px-headerGutter" data-scroll-section>
      <div style={{ top: '50%', transform: 'translateY(-50%)' }} className="relative pt-10">
        <div ref={emblaRef}>
          <div className="flex">
            <div className="w-screen-1/3 pr-20 flex" style={{ flex: '0 0 auto' }}>
              <h1 className="text-5xl xl:text-7xl self-end font-medium">{caseStudiesHeading}</h1>
            </div>
            {items.map((item) => (
              <CaseCard {...item.node} />
            ))}
          </div>
        </div>
        <div className="flex pt-10 items-center">
          <div className="h-px bg-dejima-green w-full" />
          <span className="text-dejima-red font-black w-32 pl-10 text-sm">
            0{selectedIndex + 1} of 0{items.length}
          </span>
        </div>
      </div>
    </div>
  );
};
