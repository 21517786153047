import React from 'react';

import { Link } from 'gatsby';
import { ResponsiveImage } from '../media/responsiveImage';

const CaseCard = ({ portalImage, title, projectTags, category, slug }) => {
  return (
    <Link
      to={`/case-studies/${slug && slug.current}`}
      className="w-full grid grid-rows-1 grid-cols-1"
      style={{ flex: '0 0 auto' }}
    >
      <div className="row-start-1 col-start-1 relative z-20 p-6">
        <span className="bg-dejima-red rounded-full px-10 py-1 text-white text-xs font-medium">
          {category}
        </span>
      </div>
      <div className="aspect-w-5 aspect-h-6 col-start-1 row-start-1 relative w-full">
        {portalImage && (
          <ResponsiveImage
            {...portalImage}
            className="absolute w-full h-full top-0 left-0 object-cover"
          />
        )}
      </div>
      <div className="row-start-1 col-start-1 self-end z-20 relative text-white p-6">
        <h2 className="text-4xl xl:text-5xl font-black">{title}</h2>
        <h4 className="text-lg">
          {projectTags.map((item) => (
            <>{item}, </>
          ))}
        </h4>
      </div>
    </Link>
  );
};

export const MobileViewCases = ({ items, caseStudiesHeading }) => {
  return (
    <div data-scroll-section className="px-gutter grid gap-y-10 pt-40 pb-20">
      <h1 className="text-5xl xl:text-7xl self-end font-medium">{caseStudiesHeading}</h1>
      {items.map((item) => (
        <CaseCard {...item.node} />
      ))}
    </div>
  );
};
