import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { useDefaultTheme } from '../GraphQl/useDefaultTheme';
import { useSiteConfig } from '../GraphQl/useSiteConfig';

import { CaseStudiesDesktop } from '../components/caseStudies/desktopView';
import { MobileViewCases } from '../components/caseStudies/mobileView';
import { Footer } from '../components/nav/footer';

const CaseStudies = ({ data: { allCase } }) => {
  const items = allCase.edges || [];
  const { caseStudiesHeading } = useSiteConfig();
  const breakpoints = useBreakpoint();
  const pageTheme = useDefaultTheme();

  useEffect(() => {
    if (pageTheme) {
      const themeArray = Object.entries(pageTheme);
      themeArray.forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, `${value.hex}`);
      });
    }
  }, [pageTheme]);

  return breakpoints.xs || breakpoints.sm || breakpoints.md ? (
    <>
      <MobileViewCases items={items} caseStudiesHeading={caseStudiesHeading} />
      <Footer />
    </>
  ) : (
    <CaseStudiesDesktop items={items} caseStudiesHeading={caseStudiesHeading} />
  );
};

export default CaseStudies;

export const pageQuery = graphql`
  query caseStudyQuery {
    allCase: allSanityCaseStudy {
      edges {
        node {
          title
          category
          projectTags
          portalImage {
            imageLarge {
              ...ImageWithPreview
            }
            imageSmall {
              ...ImageWithPreview
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
